@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
* { margin: 0; padding: 0; }

:root {
    --url1: #E44184;
    --url2: #6822B0;
    --url3: #32B9F1;
    --text-color: #1A1A1A;
    --secondary-color: #3D89AE;
    --note-color: #b0bbc1;
    --bg-color-main: #ffffff;
    --bg-color-secondary: #F7F7F7;
    --bg-color-third: #DBE2EC;

    --logo-text-size: 80px;
    --heading-text-size: 36px;
    --subheading-text-size: 28px;
    --section-text-size: 24px;

    --main-text-size: 16px;
    --secondary-text-size: 14px;
    --side-text-size: 12px;
    --small-text-size: 10px;

    --half-padding: 15px;
    --main-padding: 30px;
}

body {
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    font-weight: 400;
    color: #1A1A1A;
    color: var(--text-color);
    font-family: "Open Sans", Roboto, SansSerif, serif;
    line-height: 1.1;
    font-size: 16px;
    font-size: var(--main-text-size);
}

h1, h2, h3, h4, h5 .logo {
    font-family: Montserrat, Roboto, SansSerif, serif;
    font-weight: 600;
}

strong {
    font-weight: 600;
}

.logo {
    font-size: 80px;
    font-size: var(--logo-text-size)
}

h1 {
    font-size: 36px;
    font-size: var(--heading-text-size)
}

h2 {
    font-size: 28px;
    font-size: var(--subheading-text-size)
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
    font-weight: normal;
}

p {
    font-size: 16px;
    font-size: var(--main-text-size);
}

div, p { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.heading-font {
    font-family: Montserrat, Roboto, SansSerif, serif;
}

small {
    font-size: 10px;
    color: #b0bbc1;
    color: var(--note-color);
}

ul, ol {
    list-style: none;
}

.liElement {
    list-style: none;
}

li {
    line-height: 2;
}

li::before {
    content: "• ";
    margin-right: 10px;
    color: #E44184;
    color: var(--url1);
}

a {
    color: #32B9F1;
    color: var(--url3);
}

.displayMobile {
    display: none;
}

.displayWeb {
    display: block;
}

.disabledClick {
    cursor: default;
    pointer-events: none;
    opacity: .3;
}

.enabledClick {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

/*
/ LANDING
 */

.landing-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.landing-container {
    width: 100%;
    max-width: 1100px;
}

.landing-header {
    width: 100%;
    display: flex;
    padding: 0 30px;
    padding: 0 var(--main-padding);
    max-width: 1100px;
}

.landing-header-image-box {
    position: relative;
    width: 100%;
}

.landing-header-image {
    position: absolute;
    margin-top: 100px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    top: 0;
}

.landing-header-image img {
    margin-right: -100px;
    max-width: 700px;
}

.separator {
    width: 100%;
    height: 4px;
    background-color: #32B9F1;
    background-color: var(--url3);
}

.separator1px {
    height: 1px;
    margin: 0;
    padding: 0;
    border-bottom: #DBE2EC 1px solid;
    border-bottom: var(--bg-color-third) 1px solid;
}

.landing-gray-box {
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.landing-white-box {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.landing-gray-box-image {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

/*
/ MAIN CONTAINER
 */

.bg-left {
    width: 100%;
    min-height: 100vh;
    background-color: #3D89AE;
    background-color: var(--secondary-color);
    background-size: cover;
}

.bg-right {
    min-height: 100vh;
    margin-left: 20px;
    background-color: #F7F7F7;
    background-color: var(--bg-color-secondary);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 20px 30px;
}

.errorPanel {
    padding: 20px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #ff0000;
    color: #ffffff;
}

.infoPanel {
    padding: 20px;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff4a6;
    color: #000000;
    z-index: 100;
}

@-webkit-keyframes blink {
    50% { border-left: #ff0000 2px solid; }
}
@keyframes blink {
    50% { border-left: #ff0000 2px solid; }
}

.errorBorder {
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    border-left: transparent 2px solid;
    animation: blink .5s step-end infinite alternate;
    -webkit-animation: blink .5s step-end infinite alternate;
}

.errorBorderNoBlink {
    border-radius: 4px;
    padding-left: 10px;
    width: 100%;
    border-left: #ff0000 2px solid;
}

/*
/ LOGIN AREA
 */

.login-area{
    width: 100%;
    max-width: 400px;
    min-width: 320px;
    display: inline-block;
    padding: 20px;
    margin-top: 10px;
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
}

/*
/ ADMIN CONTAINERS
 */

.admin-main-container {
    padding: 30px;
    padding: var(--main-padding);
}

.admin-header {

}

.admin-container {
    display: flex;
}

.admin-form {
    width: 100%;
    margin-right: 40px;
}

.services {
    min-width: 750px;
}

.admin-preview {
   display: block;
}

.admin-phone-preview {
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    width: 375px;
    padding: 20px;
}

.save-container {
    position: fixed;
    bottom: 10px;
    padding: 15px;
    text-align: right;
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}


.bottom-space {
    height: 80px;
}

/*
/ SERVICE CONTAINERS
 */

.service-item-left {
    border-bottom: #eaeaea 1px solid;
    border-left: #eaeaea 1px solid;
    padding-left: 15px;
    padding-right: 20px;
}

.service-item {
    border-bottom: #eaeaea 1px solid;
}

.service-container {
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 20px;
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    min-width: 290px;
}

.service-heading {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: #E44184 1px solid;
    border-bottom: var(--url1) 1px solid;
    padding-bottom: 5px;
}

/*
/ TABBAR
 */


.tabbar {
    margin: 20px 0;
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    border-bottom: #32B9F1 1px solid;
    border-bottom: var(--url3) 1px solid;
    display: flex;
}

.tabbar-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.tab {

    padding: 0 20px;
    color: #3D89AE;
    color: var(--secondary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 40px;
}

.tab:hover {
    background-color: #E6F5FB;
    color: #3D89AE;
    color: var(--secondary-color);
}

.tab label {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    color: #32B9F1;
    color: var(--url3);
    font-size: 14px;
    font-size: var(--secondary-text-size);
}

.selected-tab {
    background-color: #32B9F1;
    background-color: var(--url3);
    color: #ffffff;
    color: var(--bg-color-main);
    cursor: auto;
}

.selected-tab label{
    color: #ffffff;
    color: var(--bg-color-main);
    cursor: auto;
}

.selected-tab:hover {
    background-color: #32B9F1;
    background-color: var(--url3);
    color: #ffffff;
    color: var(--bg-color-main);
}


/*
/ PREVIEW TEXT SECTION
 */

.text-block {
    margin-bottom: 20px;
    white-space: pre-wrap;
}

.text-block p {
    margin-top: 5px;
    font-size: 14px;
    font-size: var(--secondary-text-size);
}

/*
.text-block div * {
    margin: 10px 0;
}

 */

.text-block span {
    font-size: 12px;
    font-size: var(--side-text-size);
}

.service-preview p {
    margin-top: 15px;
    margin-bottom: 15px;
}

.service-preview h3 {
    margin-top: 15px;
    margin-bottom: 20px;
}

.service-preview button {
    margin-right: 10px;
    margin-bottom: 10px;
}

@media (max-width:680px) {
    .admin-preview {
        display: none;
    }
}

/*
/ OPERATIONS SECTION
 */

.operations-timerow {
    display: flex;
    flex-wrap: wrap;
    border-bottom: #DBE2EC 2px solid;
    border-bottom: var(--bg-color-third) 2px solid;
    padding-bottom: 20px;
}

.grid-bg {
    border-radius: 6px;
    background-color: #DBE2EC;
    background-color: var(--bg-color-third);
}

.validation-bg-on {
    border: red 1px solid;
}

.operations-check-bg {
    width: 25px;
    height: 25px;
    background-color: #DBE2EC;
    background-color: var(--bg-color-third);
    border-radius: 6px;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    border: #ffffff 1px solid;
    border: var(--bg-color-main) 1px solid;
}

.operations-check-bg-selected {
    background-color: #32B9F1;
    background-color: var(--url3);
}


/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --logo-text-size: 46px;
        --heading-text-size: 26px;
        --subheading-text-size: 20px;
        --section-text-size: 18px;
        --main-text-size: 15px;

        --main-padding: 20px;
        --half-padding: 10px;
    }

    .displayMobile {
        display: block;
    }

    .displayWeb {
        display: none;
    }

    .admin-form {
        width: 100%;
        margin-right: 0;
    }

    .services {
        min-width: 290px;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --logo-text-size: 40px;
        --heading-text-size: 24px;
        --subheading-text-size: 18px;
        --section-text-size: 16px;
        --main-text-size: 15px;

        --main-padding: 15px;
        --half-padding: 8px;
    }

}

/* margin ----------- */

.m-10 {margin: 10px}
.m-15 {margin: 15px}
.m-20 {margin: 20px}
.m-30 {margin: 30px}

/* margin-top */
.mt-0 {margin-top: 0}
.mt-5 {margin-top: 5px}
.mt-6 {margin-top: 6px}
.mt-8 {margin-top: 8px}
.mt-10 {margin-top: 10px}
.mt-15 {margin-top: 15px}
.mt-20 {margin-top: 20px}
.mt-25 {margin-top: 25px}
.mt-30 {margin-top: 30px}
.mt-40 {margin-top: 40px}
.mt-50 {margin-top: 50px}

/* margin-bottom */
.mb-0 {margin-bottom: 0}
.mb-5 {margin-bottom: 5px}
.mb-10 {margin-bottom: 10px}
.mb-15 {margin-bottom: 15px}
.mb-20 {margin-bottom: 20px}
.mb-30 {margin-bottom: 30px}

/* margin-left */
.ml-5 {margin-left: 5px}
.ml-10 {margin-left: 10px}
.ml-15 {margin-left: 15px}
.ml-20 {margin-left: 20px}
.ml-30 {margin-left: 30px}
.ml-40 {margin-left: 40px}
.ml-50 {margin-left: 50px}

/* margin-right */
.mr-5 {margin-right: 5px}
.mr-10 {margin-right: 10px}
.mr-15 {margin-right: 15px}
.mr-20 {margin-right: 20px}
.mr-25 {margin-right: 25px}

/* padding ----------- */

.p-10 {padding: 10px}
.p-15 {padding: 15px}
.p-20 {padding: 20px}
.p-30 {padding: 30px}

/* padding-top */
.pt-10 {padding-top: 10px}
.pt-15 {padding-top: 15px}
.pt-20 {padding-top: 20px}
.pt-30 {padding-top: 30px}

/* padding-bottom */
.pb-10 {padding-bottom: 10px}
.pb-15 {padding-bottom: 15px}
.pb-20 {padding-bottom: 20px}
.pb-30 {padding-bottom: 30px}

/* padding-left */
.pl-5 {padding-left: 5px}
.pl-10 {padding-left: 10px}
.pl-15 {padding-left: 15px}
.pl-20 {padding-left: 20px}

/* padding-right */
.pr-5 {padding-right: 5px}
.pr-10 {padding-right: 10px}
.pr-15 {padding-right: 15px}
.pr-20 {padding-right: 20px}

/* height ----------- */

.h-25 {height: 25px}
.h-30 {height: 30px}
.h-50 {height: 50px}
.h-75 {height: 75px}
.h-100 {height: 100px}


/* width ----------- */

.w-25 {width: 25px}
.w-50 {width: 50px}
.w-75 {width: 75px}
.w-100 {width: 100px}
.w-120 {width: 120px}
.w-125 {width: 125px}
.w-150 {width: 150px}
.w-160 {width: 160px}
.w-170 {width: 170px}
.w-175 {width: 175px}
.w-180 {width: 180px}
.w-200 {width: 200px}
.w-250 {width: 250px}
.w-280 {width: 280px}
.w-290 {width: 290px}
.w-300 {width: 300px}
.w-280 {width: 280px}
.w-350 {width: 350px}
.w-400 {width: 400px}

.wp-30 {width: 30%}
.wp-50 {width: 50%}
.wp-95 {width: 95%}
.wp-100 {width: 100%}

.mw-25 {min-width: 25px}
.mw-50 {min-width: 50px}
.mw-75 {min-width: 75px}
.mw-100 {min-width: 100px}
.mw-125 {min-width: 125px}
.mw-150 {min-width: 150px}
.mw-200 {min-width: 200px}
.mw-250 {min-width: 250px}
.mw-300 {min-width: 300px}
.mw-350 {min-width: 350px}
.mw-400 {min-width: 400px}

.mxw-100 {max-width: 100px}
.mxw-125 {max-width: 125px}
.mxw-150 {max-width: 150px}
.mxw-200 {max-width: 200px}
.mxw-250 {max-width: 250px}
.mxw-300 {max-width: 300px}
.mxw-350 {max-width: 350px}
.mxw-400 {max-width: 400px}

/* other ----------- */

.d-none {display: none}
.d-block {display: block}
.d-flex {display: flex}
.d-flex-wrap {display: flex; flex-wrap: wrap}
.d-flex-wrap-sb {display: flex; flex-wrap: wrap; justify-content: space-between;}
.d-flex-wrap-ac {display: flex; flex-wrap: wrap; align-items: center}
.d-flex-ac {display: flex; align-items: center}
.d-flex-sb {display: flex; justify-content: space-between;}
.d-flex-end {display: flex; justify-content: flex-end;}
.d-flex-end-ac {display: flex; justify-content: flex-end; align-items: center}
.d-flex-center {display: flex; justify-content: center; align-items: center}
.d-flex-wrap-sb-ac {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap}

.ta-left {text-align: left}
.ta-center {text-align: center}
.ta-right {text-align: right}

.fs-10 {font-size: 10px}
.fs-12 {font-size: 12px}
.fs-14 {font-size: 14px}

.fs-10-i {font-size: 10px; font-style: italic;}
.fs-12-i {font-size: 12px; font-style: italic;}
.fs-14-i {font-size: 14px; font-style: italic;}

.td-strong {font-weight: bold}
.td-underline {text-decoration: underline}

.fcblack {color: #000000;}
.fcred {color: #ff0000}
.fcwhite {color: #ffffff}
.fcgray {color: #aaaaaa}
.fcblue {color: var(--url3)}

.prelative {position: relative}
.pabsolute {position: absolute}

.tag-border {padding: 2px 4px; border-radius: 4px; border: #aaaaaa 1px solid; width: -webkit-fit-content; width: -moz-fit-content; width: fit-content }

.ww {word-break: break-word}

.fs-12-10 {font-size: 12px}
.fs-14-12 {font-size: 14px}
.fs-16-12 {font-size: 16px}

.wrapMobile {flex-wrap: nowrap}

/* mobile/web ----------- */

.mobileBlock {
    min-width: 290px;
}

.mrweb-10 {margin-right: 10px}
.mrweb-15 {margin-right: 15px}
.mrweb-20 {margin-right: 20px}
.mrweb-25 {margin-right: 25px}
.mrweb-30 {margin-right: 30px}

.mlweb-10 {margin-left: 10px}
.mlweb-15 {margin-left: 15px}
.mlweb-20 {margin-left: 20px}
.mlweb-25 {margin-left: 25px}
.mlweb-30 {margin-left: 30px}

.mbweb-10 {margin-bottom: 10px}
.mbweb-15 {margin-bottom: 15px}
.mbweb-20 {margin-bottom: 20px}
.mbweb-25 {margin-bottom: 25px}
.mbweb-30 {margin-bottom: 30px}

.mbmob-10 {margin-bottom: 0}
.mbmob-15 {margin-bottom: 0}
.mbmob-20 {margin-bottom: 0}
.mbmob-25 {margin-bottom: 0}
.mbmob-30 {margin-bottom: 0}

@media (min-width: 0px) and (max-width:450px) {
    .mrweb-10 {margin-right: 0}
    .mrweb-15 {margin-right: 0}
    .mrweb-20 {margin-right: 0}
    .mrweb-25 {margin-right: 0}
    .mrweb-30 {margin-right: 0}

    .mlweb-10 {margin-left: 0}
    .mlweb-15 {margin-left: 0}
    .mlweb-20 {margin-left: 0}
    .mlweb-25 {margin-left: 0}
    .mlweb-30 {margin-left: 0}

    .mbweb-10 {margin-bottom: 0}
    .mbweb-15 {margin-bottom: 0}
    .mbweb-20 {margin-bottom: 0}
    .mbweb-25 {margin-bottom: 0}
    .mbweb-30 {margin-bottom: 0}

    .mbmob-10 {margin-bottom: 10px}
    .mbmob-15 {margin-bottom: 15px}
    .mbmob-20 {margin-bottom: 20px}
    .mbmob-25 {margin-bottom: 25px}
    .mbmob-30 {margin-bottom: 30px}

    .mtmob-10 {margin-top: 10px}
    .mtmob-15 {margin-top: 15px}
    .mtmob-20 {margin-top: 20px}
    .mtmob-25 {margin-top: 25px}
    .mtmob-30 {margin-top: 30px}

    .wrapMobile {flex-wrap: wrap}

    .inputMobile {width: 100%; min-width: 290px}

    .mobileBlock {width: 100%}
    .mobileMinBlock {width: 290px}
    .mobileHalfBlock {width: 140px}

    .fs-12-10 {font-size: 10px}
    .fs-14-12 {font-size: 12px}
    .fs-16-12 {font-size: 12px}
}


:root {
    --main-text-size: 16px;
    --secondary-text-size: 14px;
    --side-text-size: 12px;
    --small-text-size: 10px;
}

/*
/ FORMS
 */

.form-group {
    margin-bottom: 20px;
    width: 100%;
}

.form-group p {
    font-size: 14px;
    font-size: var(--secondary-text-size);
    margin-bottom: 10px;
}

.form-datetime {
    display: flex;
}

.form-datetime .form-group {
    margin-right: 20px;
}

.form-label {
    min-height: 44px;
}

.image-preview {
    width: 100px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.validationError {
    padding: 4px 8px;
    background-color: #ff0000;
    border-radius: 4px;
    color: #ffffff;
    position: absolute;
    margin-top: -10px;
    font-size: 8px;
}

.validationBorder {
    border: #ff0000 1px solid;
    border-radius: 4px;
}

input, select, textarea  {
    width: 100%;
    height: 36px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    -ms-box-sizing:border-box;
    box-sizing:border-box;
    padding : 4px 10px;
    border-radius: 4px;
    border: 1px solid var(--bg-color-third);
    outline: none;
}

.button, .button-alt, .button-blue  {
    height: 25px;
    -ms-box-sizing:content-box;
    box-sizing:content-box;
    padding : 4px 10px;
    border-radius: 4px;
    border: 1px solid var(--bg-color-third);
    outline: none;
    cursor: pointer;
}


.button {
    background-color: var(--url1);
    color: var(--bg-color-main);
}

.button-blue {
    background-color: var(--url3);
    color: var(--bg-color-main);
}

.button-alt {
    background-color: var(--bg-color-main);
    color: var(--url3);
    border: 1px solid var(--url3);
}

.button-link {
    height: 25px;
    color: var(--url3);
    text-decoration: underline;
    box-sizing:content-box;
    padding : 4px 10px;
    border-radius: 4px;
    border: 0 solid var(--bg-color-main);
    cursor: pointer;
    background-color: unset;
    outline: none;
}

.button-link-clean {
    color: var(--url3);
    font-size: 14px;
    font-size: var(--secondary-text-size);
    cursor: pointer;
}

.button-ico {
    outline: none;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    background-color: var(--bg-color-main);
    padding-top: 5px;
    cursor: pointer;

}

.button-alt:disabled {
    cursor: auto;
    opacity: 0.3;
}

select {
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) no-repeat;
    -webkit-appearance: none;
    appearance: none;
    background-size: .65em;
    background-position: calc(100% - 1em) center;
}

select::-ms-expand {
    display: none;
}

input:focus, select:focus, textarea:focus {
    outline: none;
    border: 1px solid var(--url3);
}

textarea {
    resize: none;
    height: 150px;
}

.btn-diabled {
    pointer-events: none;
}

.btn {
    cursor: pointer;
}

.form-image {
    width: 290px;
}

.form-image-placeholder {
    width: 290px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    color: #aaaaaa;
}


.custom-check span {
    font-size: 24px;
}

.custom-check input {
    width: 20px;
    height: 20px;
}

.checkbox-container {
    display:inline-flex;
    margin-right: 10px;
    width: 36px;
    height: 22px;
    padding: 2px;
    border: var(--url3) 1px solid;
    background-color: var(--bg-color-main);
    border-radius: 16px;
    cursor: pointer;
}

.checkbox-checked {
    justify-content: flex-end;
    background-color: var(--url3);
}

.checkbox-slider {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: var(--bg-color-main);
    border: var(--url3) 1px solid;

}

/*
/ FORM ITEM WITH MENU
 */

.form-item {
    margin-top: 10px;
    margin-bottom: 10px;
    display:flex;
}

.form-item-left {
    display: flex;
    position: absolute;
}

.form-item-right {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 10;
    background-color: white;
    margin-top: 0;
    margin-right: 30px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    padding: 10px;
}

.form-item-right * {
    margin-right: 10px;
}

.form-item-menu-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}


/*
/ MEDIA
 */

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --main-text-size: 14px;
        --secondary-text-size: 12px;
        --side-text-size: 10px;
        --small-text-size: 10px;
    }
}

.slide-pane {
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    min-width: 100px;
    height: 100%;
    box-shadow: 0 8px 8px rgba(0,0,0,0.5);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    will-change: transform;
}
.slide-pane:focus {
    outline-style: none;
}
.slide-pane_from_right {
    margin-left: auto;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}
.slide-pane_from_left {
    margin-right: auto;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
}
.slide-pane_from_bottom {
    height: 90vh;
    margin-top: 10vh;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
}
.slide-pane__overlay {
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0);
}
.slide-pane__overlay.overlay-after-open {
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
    background-color: rgba(0,0,0,0);
}
.slide-pane__header {
    display: flex;
    flex: 0 0 64px;
    align-items: center;
    background: #ebebeb;
    height: 64px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.slide-pane__title-wrapper {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    min-width: 0;
}
.slide-pane .slide-pane__title {
    font-size: 18px;
    font-weight: normal;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
}
.slide-pane__close {
    margin-left: 24px;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
}
.slide-pane__close svg {
    width: 12px;
    padding: 0;
}
.slide-pane__content {
    position: relative;
    overflow-y: auto;
    padding: 24px 32px;
    flex: 1 1 auto;
}
.slide-pane__subtitle {
    font-size: 12px;
    margin-top: 2px;
}

.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
    z-index: 100;
}

@media (min-width: 0px) and (max-width:450px) {
    .slide-pane__content {
        position: relative;
        overflow-y: auto;
        padding: 20px;
        flex: 1 1 auto;
    }
    .slide-pane__close {
        margin-left: 14px;
        padding: 16px;
        opacity: 0.7;
        cursor: pointer;
    }
}

