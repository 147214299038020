.slide-pane {
    display: flex;
    flex-direction: column;
    background: #f8f8f8;
    min-width: 100px;
    height: 100%;
    box-shadow: 0 8px 8px rgba(0,0,0,0.5);
    transition: transform 0.5s;
    will-change: transform;
}
.slide-pane:focus {
    outline-style: none;
}
.slide-pane_from_right {
    margin-left: auto;
    transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
    transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
    transform: translateX(100%);
}
.slide-pane_from_left {
    margin-right: auto;
    transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
    transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
    transform: translateX(-100%);
}
.slide-pane_from_bottom {
    height: 90vh;
    margin-top: 10vh;
    transform: translateY(100%);
}
.slide-pane_from_bottom.content-after-open {
    transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
    transform: translateY(100%);
}
.slide-pane__overlay {
    z-index: 100;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0,0,0,0);
}
.slide-pane__overlay.overlay-after-open {
    background-color: rgba(0,0,0,0.3);
    transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
    background-color: rgba(0,0,0,0);
}
.slide-pane__header {
    display: flex;
    flex: 0 0 64px;
    align-items: center;
    background: #ebebeb;
    height: 64px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.slide-pane__title-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;
}
.slide-pane .slide-pane__title {
    font-size: 18px;
    font-weight: normal;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    padding: 0;
}
.slide-pane__close {
    margin-left: 24px;
    padding: 16px;
    opacity: 0.7;
    cursor: pointer;
}
.slide-pane__close svg {
    width: 12px;
    padding: 0;
}
.slide-pane__content {
    position: relative;
    overflow-y: auto;
    padding: 24px 32px;
    flex: 1 1 auto;
}
.slide-pane__subtitle {
    font-size: 12px;
    margin-top: 2px;
}

.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
    z-index: 100;
}

@media (min-width: 0px) and (max-width:450px) {
    .slide-pane__content {
        position: relative;
        overflow-y: auto;
        padding: 20px;
        flex: 1 1 auto;
    }
    .slide-pane__close {
        margin-left: 14px;
        padding: 16px;
        opacity: 0.7;
        cursor: pointer;
    }
}
