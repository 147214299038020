/* margin ----------- */

.m-10 {margin: 10px}
.m-15 {margin: 15px}
.m-20 {margin: 20px}
.m-30 {margin: 30px}

/* margin-top */
.mt-0 {margin-top: 0}
.mt-5 {margin-top: 5px}
.mt-6 {margin-top: 6px}
.mt-8 {margin-top: 8px}
.mt-10 {margin-top: 10px}
.mt-15 {margin-top: 15px}
.mt-20 {margin-top: 20px}
.mt-25 {margin-top: 25px}
.mt-30 {margin-top: 30px}
.mt-40 {margin-top: 40px}
.mt-50 {margin-top: 50px}

/* margin-bottom */
.mb-0 {margin-bottom: 0}
.mb-5 {margin-bottom: 5px}
.mb-10 {margin-bottom: 10px}
.mb-15 {margin-bottom: 15px}
.mb-20 {margin-bottom: 20px}
.mb-30 {margin-bottom: 30px}

/* margin-left */
.ml-5 {margin-left: 5px}
.ml-10 {margin-left: 10px}
.ml-15 {margin-left: 15px}
.ml-20 {margin-left: 20px}
.ml-30 {margin-left: 30px}
.ml-40 {margin-left: 40px}
.ml-50 {margin-left: 50px}

/* margin-right */
.mr-5 {margin-right: 5px}
.mr-10 {margin-right: 10px}
.mr-15 {margin-right: 15px}
.mr-20 {margin-right: 20px}
.mr-25 {margin-right: 25px}

/* padding ----------- */

.p-10 {padding: 10px}
.p-15 {padding: 15px}
.p-20 {padding: 20px}
.p-30 {padding: 30px}

/* padding-top */
.pt-10 {padding-top: 10px}
.pt-15 {padding-top: 15px}
.pt-20 {padding-top: 20px}
.pt-30 {padding-top: 30px}

/* padding-bottom */
.pb-10 {padding-bottom: 10px}
.pb-15 {padding-bottom: 15px}
.pb-20 {padding-bottom: 20px}
.pb-30 {padding-bottom: 30px}

/* padding-left */
.pl-5 {padding-left: 5px}
.pl-10 {padding-left: 10px}
.pl-15 {padding-left: 15px}
.pl-20 {padding-left: 20px}

/* padding-right */
.pr-5 {padding-right: 5px}
.pr-10 {padding-right: 10px}
.pr-15 {padding-right: 15px}
.pr-20 {padding-right: 20px}

/* height ----------- */

.h-25 {height: 25px}
.h-30 {height: 30px}
.h-50 {height: 50px}
.h-75 {height: 75px}
.h-100 {height: 100px}


/* width ----------- */

.w-25 {width: 25px}
.w-50 {width: 50px}
.w-75 {width: 75px}
.w-100 {width: 100px}
.w-120 {width: 120px}
.w-125 {width: 125px}
.w-150 {width: 150px}
.w-160 {width: 160px}
.w-170 {width: 170px}
.w-175 {width: 175px}
.w-180 {width: 180px}
.w-200 {width: 200px}
.w-250 {width: 250px}
.w-280 {width: 280px}
.w-290 {width: 290px}
.w-300 {width: 300px}
.w-280 {width: 280px}
.w-350 {width: 350px}
.w-400 {width: 400px}

.wp-30 {width: 30%}
.wp-50 {width: 50%}
.wp-95 {width: 95%}
.wp-100 {width: 100%}

.mw-25 {min-width: 25px}
.mw-50 {min-width: 50px}
.mw-75 {min-width: 75px}
.mw-100 {min-width: 100px}
.mw-125 {min-width: 125px}
.mw-150 {min-width: 150px}
.mw-200 {min-width: 200px}
.mw-250 {min-width: 250px}
.mw-300 {min-width: 300px}
.mw-350 {min-width: 350px}
.mw-400 {min-width: 400px}

.mxw-100 {max-width: 100px}
.mxw-125 {max-width: 125px}
.mxw-150 {max-width: 150px}
.mxw-200 {max-width: 200px}
.mxw-250 {max-width: 250px}
.mxw-300 {max-width: 300px}
.mxw-350 {max-width: 350px}
.mxw-400 {max-width: 400px}

/* other ----------- */

.d-none {display: none}
.d-block {display: block}
.d-flex {display: flex}
.d-flex-wrap {display: flex; flex-wrap: wrap}
.d-flex-wrap-sb {display: flex; flex-wrap: wrap; justify-content: space-between;}
.d-flex-wrap-ac {display: flex; flex-wrap: wrap; align-items: center}
.d-flex-ac {display: flex; align-items: center}
.d-flex-sb {display: flex; justify-content: space-between;}
.d-flex-end {display: flex; justify-content: flex-end;}
.d-flex-end-ac {display: flex; justify-content: flex-end; align-items: center}
.d-flex-center {display: flex; justify-content: center; align-items: center}
.d-flex-wrap-sb-ac {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap}

.ta-left {text-align: left}
.ta-center {text-align: center}
.ta-right {text-align: right}

.fs-10 {font-size: 10px}
.fs-12 {font-size: 12px}
.fs-14 {font-size: 14px}

.fs-10-i {font-size: 10px; font-style: italic;}
.fs-12-i {font-size: 12px; font-style: italic;}
.fs-14-i {font-size: 14px; font-style: italic;}

.td-strong {font-weight: bold}
.td-underline {text-decoration: underline}

.fcblack {color: #000000;}
.fcred {color: #ff0000}
.fcwhite {color: #ffffff}
.fcgray {color: #aaaaaa}
.fcblue {color: var(--url3)}

.prelative {position: relative}
.pabsolute {position: absolute}

.tag-border {padding: 2px 4px; border-radius: 4px; border: #aaaaaa 1px solid; width: fit-content }

.ww {word-break: break-word}

.fs-12-10 {font-size: 12px}
.fs-14-12 {font-size: 14px}
.fs-16-12 {font-size: 16px}

.wrapMobile {flex-wrap: nowrap}

/* mobile/web ----------- */

.mobileBlock {
    min-width: 290px;
}

.mrweb-10 {margin-right: 10px}
.mrweb-15 {margin-right: 15px}
.mrweb-20 {margin-right: 20px}
.mrweb-25 {margin-right: 25px}
.mrweb-30 {margin-right: 30px}

.mlweb-10 {margin-left: 10px}
.mlweb-15 {margin-left: 15px}
.mlweb-20 {margin-left: 20px}
.mlweb-25 {margin-left: 25px}
.mlweb-30 {margin-left: 30px}

.mbweb-10 {margin-bottom: 10px}
.mbweb-15 {margin-bottom: 15px}
.mbweb-20 {margin-bottom: 20px}
.mbweb-25 {margin-bottom: 25px}
.mbweb-30 {margin-bottom: 30px}

.mbmob-10 {margin-bottom: 0}
.mbmob-15 {margin-bottom: 0}
.mbmob-20 {margin-bottom: 0}
.mbmob-25 {margin-bottom: 0}
.mbmob-30 {margin-bottom: 0}

@media (min-width: 0px) and (max-width:450px) {
    .mrweb-10 {margin-right: 0}
    .mrweb-15 {margin-right: 0}
    .mrweb-20 {margin-right: 0}
    .mrweb-25 {margin-right: 0}
    .mrweb-30 {margin-right: 0}

    .mlweb-10 {margin-left: 0}
    .mlweb-15 {margin-left: 0}
    .mlweb-20 {margin-left: 0}
    .mlweb-25 {margin-left: 0}
    .mlweb-30 {margin-left: 0}

    .mbweb-10 {margin-bottom: 0}
    .mbweb-15 {margin-bottom: 0}
    .mbweb-20 {margin-bottom: 0}
    .mbweb-25 {margin-bottom: 0}
    .mbweb-30 {margin-bottom: 0}

    .mbmob-10 {margin-bottom: 10px}
    .mbmob-15 {margin-bottom: 15px}
    .mbmob-20 {margin-bottom: 20px}
    .mbmob-25 {margin-bottom: 25px}
    .mbmob-30 {margin-bottom: 30px}

    .mtmob-10 {margin-top: 10px}
    .mtmob-15 {margin-top: 15px}
    .mtmob-20 {margin-top: 20px}
    .mtmob-25 {margin-top: 25px}
    .mtmob-30 {margin-top: 30px}

    .wrapMobile {flex-wrap: wrap}

    .inputMobile {width: 100%; min-width: 290px}

    .mobileBlock {width: 100%}
    .mobileMinBlock {width: 290px}
    .mobileHalfBlock {width: 140px}

    .fs-12-10 {font-size: 10px}
    .fs-14-12 {font-size: 12px}
    .fs-16-12 {font-size: 12px}
}

