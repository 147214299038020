:root {
    --main-text-size: 16px;
    --secondary-text-size: 14px;
    --side-text-size: 12px;
    --small-text-size: 10px;
}

/*
/ FORMS
 */

.form-group {
    margin-bottom: 20px;
    width: 100%;
}

.form-group p {
    font-size: var(--secondary-text-size);
    margin-bottom: 10px;
}

.form-datetime {
    display: flex;
}

.form-datetime .form-group {
    margin-right: 20px;
}

.form-label {
    min-height: 44px;
}

.image-preview {
    width: 100px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.validationError {
    padding: 4px 8px;
    background-color: #ff0000;
    border-radius: 4px;
    color: #ffffff;
    position: absolute;
    margin-top: -10px;
    font-size: 8px;
}

.validationBorder {
    border: #ff0000 1px solid;
    border-radius: 4px;
}

input, select, textarea  {
    width: 100%;
    height: 36px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    -ms-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    padding : 4px 10px;
    border-radius: 4px;
    border: 1px solid var(--bg-color-third);
    outline: none;
}

.button, .button-alt, .button-blue  {
    height: 25px;
    -ms-box-sizing:content-box;
    -moz-box-sizing:content-box;
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    padding : 4px 10px;
    border-radius: 4px;
    border: 1px solid var(--bg-color-third);
    outline: none;
    cursor: pointer;
}


.button {
    background-color: var(--url1);
    color: var(--bg-color-main);
}

.button-blue {
    background-color: var(--url3);
    color: var(--bg-color-main);
}

.button-alt {
    background-color: var(--bg-color-main);
    color: var(--url3);
    border: 1px solid var(--url3);
}

.button-link {
    height: 25px;
    color: var(--url3);
    text-decoration: underline;
    box-sizing:content-box;
    padding : 4px 10px;
    border-radius: 4px;
    border: 0 solid var(--bg-color-main);
    cursor: pointer;
    background-color: unset;
    outline: none;
}

.button-link-clean {
    color: var(--url3);
    font-size: var(--secondary-text-size);
    cursor: pointer;
}

.button-ico {
    outline: none;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    background-color: var(--bg-color-main);
    padding-top: 5px;
    cursor: pointer;

}

.button-alt:disabled {
    cursor: auto;
    opacity: 0.3;
}

select {
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-size: .65em;
    background-position: calc(100% - 1em) center;
}

select::-ms-expand {
    display: none;
}

input:focus, select:focus, textarea:focus {
    outline: none;
    border: 1px solid var(--url3);
}

textarea {
    resize: none;
    height: 150px;
}

.btn-diabled {
    pointer-events: none;
}

.btn {
    cursor: pointer;
}

.form-image {
    width: 290px;
}

.form-image-placeholder {
    width: 290px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    color: #aaaaaa;
}


.custom-check span {
    font-size: 24px;
}

.custom-check input {
    width: 20px;
    height: 20px;
}

.checkbox-container {
    display:inline-flex;
    margin-right: 10px;
    width: 36px;
    height: 22px;
    padding: 2px;
    border: var(--url3) 1px solid;
    background-color: var(--bg-color-main);
    border-radius: 16px;
    cursor: pointer;
}

.checkbox-checked {
    justify-content: flex-end;
    background-color: var(--url3);
}

.checkbox-slider {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: var(--bg-color-main);
    border: var(--url3) 1px solid;

}

/*
/ FORM ITEM WITH MENU
 */

.form-item {
    margin-top: 10px;
    margin-bottom: 10px;
    display:flex;
}

.form-item-left {
    display: flex;
    position: absolute;
}

.form-item-right {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 10;
    background-color: white;
    margin-top: 0;
    margin-right: 30px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    padding: 10px;
}

.form-item-right * {
    margin-right: 10px;
}

.form-item-menu-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
}


/*
/ MEDIA
 */

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --main-text-size: 14px;
        --secondary-text-size: 12px;
        --side-text-size: 10px;
        --small-text-size: 10px;
    }
}
